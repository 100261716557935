<template>
  <div class="all-list">
    <van-nav-bar title="备件查询" left-text="返回" fixed left-arrow @click-left="goBack" />
    <van-search class="search" placeholder="请输入搜索关键词" @search="onSearch" />
    <van-pull-refresh v-model="isRefresh" head-height="80" distance="80" @refresh="onRefresh">
      <van-list class="index-list" v-model="loading" :finished="isFinished" :finished-text="finishedText" @load="loadMore">
      <van-cell class="bq-list" v-for="item in list" :key="item.id" @click="goDetail(item.id)">
        <van-swipe-cell>
        <van-row type="flex" class="tag">
          <div class="asset-title">
            <div style="width: 78%;margin-left: 2px">{{ item.name }}</div>
            <div v-html="item.status_table"></div>
          </div>
        </van-row>
          <van-divider />
          <div style="padding-top: 8px">
            <van-row type="flex" class="details">
            <van-col>编码：{{ item.code}}</van-col>
          </van-row>
            <van-row type="flex" class="details">
              <van-col>型号：{{ item.specification }}</van-col>
            </van-row>
            <van-row type="flex" class="details">
              <van-col>品牌：{{ item.brand }}</van-col>
            </van-row>
            <van-row type="flex" class="details">
              <van-col>单位：{{ item.unit }}</van-col>
            </van-row>
          </div>
<!--          <template #right>-->
<!--            <van-button square text="删除" type="danger" @click="deleteBtn(item.id)" />-->
<!--          </template>-->
        </van-swipe-cell>
      </van-cell>
    </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import API from '@/api/stock/parts'
export default {
  inject: ['goUrl', 'reload'],

  data() {
    return {
      active: 0,
      list: [],
      value:'',
      loading: false,
      isFinished: false,
      isRefresh: false,
      finishedText:'加载完成',
      isLoading: false,
      query: {
        status: null,
        page: 0,
        limit: 5
      }
    }
  },
  created() {
    this.index()
  },
  methods: {
    async index() {
      this.isLoading = true
      if (this.isRefresh) {
        this.list = []
        this.query.page = 0
        this.isRefresh = false
        this.isFinished = false
      }
      this.query.page += 1
      const res = await API.getList(this.query)
      if (res.data.data.length === 0) { // 判断获取数据条数若等于0
        this.list = [] // 只有有搜索条件的时候，这个才有用
        this.isFinished = true	// 停止加载
      } else {
        this.list.push(...res.data.data)
        if (this.list.length === res.data.total) {
          this.isFinished = true		// 结束加载状态
        }
      }
      this.loading = false
      this.isLoading = false
    },
    // async deleteBtn(id) {
    //   const res = await API.delete({ id })
    //     if (res.data.error_code !== 1) {
    //       this.$toast(res.data.message)
    //     } else {
    //       this.$toast.success('删除成功')
    //       this.reload()
    //     }
    // },
    goBack() {
      this.goUrl('/index')
    },
    onSearch() {
      this.query.page = 1
      this.list = []
      this.index()
    },
    goDetail(id){
      const url = `/parts/edit/${id}`
      this.goUrl(url)
    },
    onRefresh() {
      this.reload()
      this.index()
    },
    loadMore() {
      if (!this.isLoading) {
        this.index()
      }
    }
  },
}
</script>

<style scoped>
  .asset-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
</style>
